<template>
  <div v-if="contentPage" class="payload-page">
    <Breadcrumbs
      v-if="showBreadcrumbs"
      :custom-breadcrumbs="contentPage.breadcrumbs"
      :page-title="pageTitle"
    />
    <h1 class="hidden-item">{{ pageTitle }}</h1>
    <component
      :is="pageBlock.componentIdentifier"
      :block-data="pageBlock"
      v-for="(pageBlock, pageBlockIndex) in contentPage.layout"
      :key="pageBlockIndex"
    />
    <Prefooter v-if="!contentPage.hidePrefooter" />
  </div>
  <LoadingDots v-else />
</template>

<script>
import {
  defineComponent,
  useMeta,
  useFetch,
  onMounted,
  computed,
  ref,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';
import LoadingDots from '~/components/General/LoadingDots.vue';
import { Blocks } from '~/components/CMS';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import { useGtm } from '~/composables';
import seoHelpers from '~/helpers/seo/seoHelpers';
import {
  generateEventStructuredData,
  generateBreadcrumbStructuredData,
} from '~/helpers/seo/structuredData';

export default defineComponent({
  name: 'PayloadPage',
  components: {
    LoadingDots,
    ...Blocks,
  },
  head: {},
  props: {
    contentPage: {
      type: Object,
      required: true,
      validator: (value) => !!(value.layout && value.layout.length > 0),
    },
    showBreadcrumbs: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const { addTags } = useCache();
    const { pageViewPush } = useGtm();
    const { getAlternates, getCanonical } = seoHelpers();
    const structuredEventData = ref(null);
    const structuredBreadcrumbData = ref(null);
    const alternates = ref([]);
    const canonical = ref({});
    const {
      app: { localePath },
      $config: { storeUrl },
    } = useContext();

    useFetch(async () => {
      alternates.value = !route.value.name.includes('home')
        ? await getAlternates('payloadPage', true)
        : [];
      canonical.value = !route.value.name.includes('home')
        ? getCanonical()
        : {};
      if (props?.contentPage?.structuredData) {
        structuredEventData.value = generateEventStructuredData(
          props.contentPage.structuredData
        );
        structuredBreadcrumbData.value = generateBreadcrumbStructuredData(
          props.contentPage.breadcrumbs,
          'payload',
          localePath,
          storeUrl
        );
      }
      addTags([
        { prefix: CacheTagPrefix.View, value: 'page' },
        {
          prefix: CacheTagPrefix.View,
          value: props.contentPage?.id || 'payloadPage',
        },
      ]);
    });

    onMounted(() => {
      pageViewPush('CMS');
    });

    const pageTitle = computed(
      () =>
        props?.contentPage?.title ||
        props?.contentPage?.meta?.title ||
        'Tre Ponti'
    );

    useMeta(() => ({
      title: pageTitle.value,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content:
            props?.contentPage?.meta?.title || props?.contentPage?.title || '',
        },
        {
          hid: 'description',
          name: 'description',
          content: props?.contentPage?.meta?.description || '',
        },
      ],
      link: [...alternates.value, canonical.value],
      script: [
        {
          json: structuredEventData.value,
          type: 'application/ld+json',
        },
        {
          json: structuredBreadcrumbData.value,
          type: 'application/ld+json',
        },
      ],
    }));

    return {
      pageTitle,
      structuredEventData,
      structuredBreadcrumbData,
    };
  },
});
</script>

<style lang="scss" scoped>
.payload-page {
  position: relative;
}
</style>
